import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { changeMonitoringLevel1, changeMonitoringLevel2, changeMonitoringLevel3, changePolicyendDate, changePolicystartDate } from '../redux/action';

const FarmerTab = () => {
  const LoanID = useSelector((state) => state.LoanID);
  
  // State to store farmer details and loan data
  const [farmerDetails, setFarmerDetails] = useState({});
  const [loanDetails, setLoanDetails] = useState([]);
  const [country, setcountry] = useState([]);
  const MonitoringLevel1 = useSelector((state) => state.MonitoringLevel1);
  const MonitoringLevel2 = useSelector((state) => state.MonitoringLevel2);
  const MonitoringLevel3 = useSelector((state) => state.MonitoringLevel3);
  const PolicystartDate = useSelector((state) => state.PolicystartDate);
  const PolicyendDate = useSelector((state) => state.PolicyendDate);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchFarmerDetails = async () => {
      try {
        const response = await fetch(`/api/monitoring/farmerDetail?loan_id=${LoanID}`);
        const data = await response.json();
        const farmerDetails = data[0];
        setFarmerDetails(farmerDetails);
  
        // Parse season_start and season_end as "DD/MM/YY" and convert to "YYYY-MM-DD"
        const parseDate = (dateStr) => {
          const [day, month, year] = dateStr.split('/');
          // Ensure the year has 4 digits (add 2000 for years like 24 -> 2024)
          const fullYear = year.length === 2 ? `20${year}` : year;
          return `${fullYear}-${month}-${day}`;
        };
  
        const formattedStartDate = parseDate(farmerDetails.season_start);
        const formattedEndDate = parseDate(farmerDetails.season_end);
        dispatch(changePolicyendDate(formattedEndDate));
        dispatch(changePolicystartDate(formattedStartDate));
        

        // Split the location and trim whitespace
        const locationArray = farmerDetails.location.split(', ').map(location => location.trim());
        if (locationArray.length >= 4) {
          setcountry(locationArray[0]);
          dispatch(changeMonitoringLevel1(locationArray[1]));
          dispatch(changeMonitoringLevel2(locationArray[2]));
          dispatch(changeMonitoringLevel3(locationArray[3]));
        } else {
          console.error('Location does not have enough parts');
        }
      } catch (error) {
        console.error('Error fetching farmer details:', error);
      }
    };
  
    const fetchLoanDetails = async () => {
      try {
        const response = await fetch(`/api/monitoring/pastLoans?loan_id=${LoanID}`);
        const data = await response.json();
        setLoanDetails(data);
      } catch (error) {
        console.error('Error fetching loan details:', error);
      }
    };
  
    if (LoanID) {
      fetchFarmerDetails();
      fetchLoanDetails();
    }
  }, [LoanID]);
  
  
  return (
    <div>
      {/* Farmer Details Paper */}
      <Paper
        sx={{
          height: '88px',
          padding: '16px 32px',
          borderRadius: '8px 0px 0px 0px',
          border: '0.4px solid transparent',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#FBFDFF',
          opacity: 1,
          marginBottom: '20px',
        }}
      >
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Full Name
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            {farmerDetails.full_name || 'Otieno Odhiambo'}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Educational Level
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            Secondary
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Gender
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            {farmerDetails.gender || 'Male'}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Age
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            {farmerDetails.age || '35'}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Contact Number
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            {farmerDetails.contact_number || '+1234567890'}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
            Location
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            {farmerDetails.location || 'Kenya, Trans Nzoia, Kipkeikei'}
          </Typography>
        </Box>
      </Paper>

      <Typography variant="h6" sx={{ marginBottom: '16px' }}>Past Loans</Typography>

      {/* Past Loans Cards */}
      {loanDetails.map((loan, index) => (
        <Paper
          key={index}
          sx={{
            padding: '16px 32px',
            borderRadius: '8px',
            border: '1px solid lightgrey',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '16px',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Start Date - End Date
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan['Start Date - End Date']}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Loan Type
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan['Loan Type']}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Acreage
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.Acreage} acres
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Crop Name
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.Crop}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Crop Variety
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan['Crop Variety']}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Insured
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.Insured}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Season
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.Season}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '20px' }}>
              Repayment
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
              {loan.Repayment}
            </Typography>
          </Box>
        </Paper>
      ))}
    </div>
  );
};

export default FarmerTab;
