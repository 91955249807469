import React, { useState } from "react";
import "../../mainScreen.css";
import { FaUserAlt } from "react-icons/fa";
import { IoMdHome, IoMdMenu } from "react-icons/io";
import { IoIosSettings, IoIosArrowForward } from "react-icons/io";
import { Auth } from "aws-amplify";
import { IoLogOutOutline } from "react-icons/io5";
import { FaCircleUser } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSelector } from "react-redux";

const AddUserGroup = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const LoginEmail = useSelector((state) => state.LoginEmail);
  const [groupName, setGroupName] = useState(""); // State for user group input
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleMenuToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleSave = async () => {
    // Prepare request payload
    const payload = {
      group_name: groupName,
    };
    const { accessToken: { jwtToken } } = await Auth.currentSession();
    try {
      // API call to add user group
      const response = await fetch("/api/admin/addGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("User group added successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        const errorData = await response.json();
        toast.error(`Failed to add user group: ${errorData.message}`, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="main-screen" style={{ display: "flex" }}>
     <div
        style={{
          background: "#00253D",
          height: "100vh",
          zIndex: "1001",
          position: "relative",
          width: isCollapsed ? "80px" : "auto", // Adjust width for collapsed state
          transition: "width 0.3s",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "100%",
            }}
          >
            <IoMdMenu color="white" size={28} onClick={handleMenuToggle} />
            {!isCollapsed && (
              <span
                style={{ marginLeft: "10px", color: "white", fontSize: "22px" }}
              >
                TARA
              </span>
            )}
          </div>


          <div
            className="custom-buttonnav"
            style={{
              padding: "20px",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => {
              props.setadminProfilescreen(true);
              props.setaddUserRoleScreen(false);
              props.setaddUserGroupScreen(false);
              props.setadminSettingsScreen(false);
              props.setoutputportfolioscreen(false);
            }}
          >
            <FaUserAlt
              color="white"
              size={28}
              style={{ marginRight: isCollapsed ? "0" : "10px" }}
            />
            {!isCollapsed && (
              <span style={{ color: "white", fontSize: "18px" }}>
                User Management
              </span>
            )}
          </div>

          <div
            className="custom-buttonnav"
            style={{
              padding: "20px",
              display: "flex",
              cursor: "pointer",
              background: "#F5821F",
            }}
          >
            <IoIosSettings
              color="white"
              size={28}
              style={{ marginRight: isCollapsed ? "0" : "10px" }}
            />
            {!isCollapsed && (
              <span style={{ color: "white", fontSize: "18px" }}>
                User Settings
              </span>
            )}
          </div>
        </div>

        <div style={{ position: "relative", width: "100%" }}>
          <div
            className="custom-buttonnav"
            style={{
              padding: "15px",
              display: "flex",
              width: "auto",
              cursor: "pointer",
              alignItems: "center",
              borderTop: "2px solid white",
              position: "relative",
            }}
            onClick={togglePopup}
          >
            {showPopup && (
              <div
                style={{
                  position: "absolute",
                  top: "-110px",
                  left: "10px",
                  backgroundColor: "white",
                  color: "black",
                  padding: "10px",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: "1000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props.setadminProfilescreen(false);
                    props.setadvisorycreen(false);
                    props.setmonitorscreen(false);
                    props.setMainscreen(false);
                    props.setcalulateriskmainscreen(false);
                    props.setoutputportfolioscreen(true);
                  }}
                >
                  <IoSettingsOutline style={{ marginRight: "8px" }} /> Switch as
                  user
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                >
                  <IoSettingsOutline style={{ marginRight: "8px" }} /> Settings
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                  onClick={handleLogout}
                >
                  <IoLogOutOutline style={{ marginRight: "8px" }} /> Logout
                </div>
              </div>
            )}

            <FaCircleUser
              color="white"
              size={25}
              style={{ marginRight: isCollapsed ? "0" : "8px" }}
            />
            {!isCollapsed && (
              <>
                <span
                  style={{
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                 {LoginEmail}
                </span>
                <IoIosArrowForward color="white" size={20} />
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          {/* Back Icon */}
          <button
            onClick={() => {
              props.setaddUserRoleScreen(false);
              props.setadminSettingsScreen(true);
              props.setaddUserGroupScreen(false);
            }}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            <IoIosArrowBack size={25} />
          </button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h1 style={{ fontSize: "28px", fontWeight: "bold" }}>
              Add User Group
            </h1>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #D3D2CE",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <h2>User Group Detail</h2>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
            }}
          >
            {/* User Group Name Input */}
            <div style={{ marginRight: "10px", width: "380px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: 700,
                }}
              >
                User Group
              </label>
              <input
                type="text"
                placeholder="Enter user group"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            {/* Date Added */}
            <div style={{ marginRight: "10px", width: "380px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: 700,
                }}
              >
                Date Added
              </label>
              <TextField
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                  },
                }}
                style={{ width: "100%" }} // Ensure TextField takes full width
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{
                color: "#00253D",
                marginRight: "10px",
                background: "none",
                width: "170px",
              }}
              onClick={() => {
                props.setadminSettingsScreen(true);
                props.setaddUserGroupScreen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#00253D",
                width: "170px",
                color: "white",
              }}
              onClick={handleSave} // Save button logic
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddUserGroup;
