import { Style, Stroke, Fill, Icon, Text } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
import { MyLayers, VectorLayer } from "./Layers";
import MyMapSmall from "./Map/MyMapSmall";
import { vector, wms } from "./Source";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeZoom } from "../../redux/action";
import DrawOnMap from "./Draw/DrawOnMap";
import { useMap } from "../hooks/useMap";
import { toStringHDMS } from "ol/coordinate";
import VectorSource from "ol/source/Vector";
import VectorLayerOL from "ol/layer/Vector";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import TileLayer from "./Layers/TileLayer";
import { getFloodColor, getSpiColor } from "../lib/gis";
import { makeStyles } from '@mui/styles';
import { Snackbar, Button, SnackbarContent } from '@mui/material';
import { IoCloseCircleSharp } from "react-icons/io5";
import IconButton from '@mui/material/IconButton';
import { LuDownload } from "react-icons/lu";
import { Auth } from "aws-amplify";



const useStyles = makeStyles((theme) => ({
  snackbarContent: {
    backgroundColor: 'white', // Set background color to white
    color: 'black', // Ensure text color contrasts with white background
    right: 0,
    bottom: 0,
  },
  message: {
    whiteSpace: 'pre-line', // Allows handling new lines within the text
  },
  snackbar: {
    position: 'absolute',
  }
}));

function MapViewAnalyticsSmall({ featureData, selectedState, selectedConstituency }) {
  const { map, fishnetLayer } = useMap();
  const [center, setCenter] = useState([32.290275, 1.373333]);
  //   const [data, setData] = useState();
  const [drawIsActive, setDrawIsActive] = useState(false);
  const [coords, setCoords] = useState();
  const [mapRef, setMapRef] = useState();
  const [markerLayer, setMarkerLayer] = useState(null);
  const dispatch = useDispatch();
  const zoom = useSelector((state) => state.zoom);
  const [open, setOpen] = useState(false);
  const county = useSelector((state) => state.level1Data);
  const subcounty = useSelector((state) => state.level2Data);
  const ward = useSelector((state) => state.level3Data);
  const [popUpData, setPopUpData] = useState('Total Loan Amount : 12344 and Highest Loan Crop : Maize');
  const classes = useStyles();

  const handleDownloadCounty = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      const response = await fetch(`/api/homepage/downloadPortfolioCountyWise?county=${county}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Portfolio.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const handleDownloadSubCounty = async () => {
    try {
      const response = await fetch(`/api/homepage/downloadPortfolioSubCountyWise?subcounty=${subcounty}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Portfolio.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } catch (error) {
      console.error('Download error:', error);
    }
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    //call county level api and change status of pop up to true and message should be loan and crop
    handleClose();
    fetch(`/api/homepage/countyWiseTotalLoanAndCrop?level=county&county=${county}`)
      .then(response => response.json())
      .then((data) => {
        if (county)
          setOpen(true)
        if (data.length != 0)
          setPopUpData(<div><IconButton size="small" onClick={handleClose} style={{ color: 'red',float:'right',marginRight:'-50px',marginTop:'-10px'}}><IoCloseCircleSharp /></IconButton><span>Total Loan Amount: {data[0].total_loan_amount}<br />The Highest loan crop is: {data[0].crop_name}</span><div style={{ textAlign: 'center' }}><div className="custom-buttontop2" style={{ width: '120px' }} onClick={handleDownloadCounty} ><LuDownload color="white" size={14} style={{ marginRight: '10px' }} />Download</div></div></div>)
        else
        setPopUpData(<div style={{display:'flex'}}><div>No Data Available !</div><IconButton size="small" onClick={handleClose} style={{ color: 'red',marginLeft:'13vw'}}><IoCloseCircleSharp /></IconButton></div>)
      })
      .catch(error => console.error('Error in finding county', error));

  }, [county])

  useEffect(() => {
    //call subcounty level api and change status of pop up to true and message should be loan and crop
    handleClose();
    fetch(`/api/homepage/countyWiseTotalLoanAndCrop?level=subcounty&subcounty=${subcounty}`)
      .then(response => response.json())
      .then((data) => {
        if (subcounty)
          setOpen(true)
        if (data.length != 0)
          setPopUpData(<div><IconButton size="small" onClick={handleClose} style={{ color: 'red',float:'right',marginRight:'-50px',marginTop:'-10px'}}><IoCloseCircleSharp /></IconButton><span>Total Loan Amount: {data[0].total_loan_amount}<br />Highest Loan Crop: {data[0].crop_name}</span><div style={{ textAlign: 'center' }}><div className="custom-buttontop2" style={{ width: '120px' }} onClick={handleDownloadSubCounty} ><LuDownload color="white" size={14} style={{ marginRight: '10px' }} />Download</div></div></div>)
        else
        setPopUpData(<div style={{display:'flex'}}><div>No data Available !</div><IconButton size="small" onClick={handleClose} style={{ color: 'red',marginLeft:'13vw'}}><IoCloseCircleSharp /></IconButton></div>)

      })
      .catch(error => console.error('Error in finding county', error))


  }, [subcounty])



  useEffect(() => {
    if (!map) return;
    dispatch(changeZoom(5));
    map.on("pointermove", function (event) {
      if (event.coordinate) {
        const coordinates = toStringHDMS(event.coordinate);
        setCoords(coordinates);
      } else {
        setCoords();
      }
    });

    map.on("singleclick", function (event) {
      let fishnetWmslayerVar = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "fishnetWms");

      let url = null;
      if (fishnetWmslayerVar) {
        const viewResolution = map.getView().getResolution();
        url = fishnetWmslayerVar
          .getSource()
          .getFeatureInfoUrl(event.coordinate, viewResolution, "EPSG:4326", {
            INFO_FORMAT: "application/json",
          });
      }
      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            map.removeLayer(
              map
                .getLayers()
                .getArray()
                .find((layer) => layer.get("name") === "highlightLayer")
            );
            if (data.features.length !== 0) {
              let feature = data.features[0].properties;
              if (feature) {
                let geoJSon = data.features[0];
                let PixelID = feature.PixelID;
                let PixelNames = feature.PixelNames;
                //console.log("PixelID", PixelID);
                //console.log("PixelNames", PixelNames);
                let highlightFeature = new GeoJSON().readFeature(geoJSon);
                let highlightLayer = new VectorLayerOL({
                  source: new VectorSource({
                    features: [highlightFeature],
                  }),
                  zIndex: 90,
                  style: new Style({
                    stroke: new Stroke({
                      color: "rgba(0, 255, 0, 1)",
                    }),
                    fill: new Fill({
                      color: "rgba(0, 255, 0, 0.3)",
                    }),
                  }),
                });
                map.addLayer(highlightLayer);
                highlightLayer.set("name", "highlightLayer");
              }
            }
          });
      }
    });

    // Initialize marker layer
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayerOL({
      source: markerSource,
    });
    map.addLayer(markerLayer);
    setMarkerLayer(markerLayer);

    return () => {
      map.removeLayer(markerLayer);
    };


  }, [map]);


  useEffect(() => {

  }, [])


  const handleZoomIn = () => {
    // Implement the logic for zooming in or out
    dispatch(changeZoom(zoom + 1));
  };

  const handleZoomOut = () => {
    // Implement the logic for zooming in or out
    dispatch(changeZoom(zoom - 1));
  };

  const handleCurrentLocation = () => {
    // Implement the logic for handling current location
    navigator.geolocation.getCurrentPosition(
      // Success callback
      (position) => {
        const { latitude, longitude } = position.coords;
        // Clear previous markers
        markerLayer.getSource().clear();
        // Add new marker
        const marker = new Feature({
          geometry: new Point(fromLonLat([longitude, latitude])),
        });
        marker.getGeometry().transform("EPSG:3857", "EPSG:4326");
        marker.setStyle(
          new Style({
            image: new Icon({
              src: "/location-pin.png",
            }),
          })
        );
        markerLayer.getSource().addFeature(marker);
        map
          .getView()
          .animate({ center: [longitude, latitude], zoom: 12, duration: 1000 });
        // Set map center to current location
        // map.current.setCenter({ lat: latitude, lng: longitude });
      }
    );
  };

  const handlePictureFrame = async () => {
    // Draw the map to the canvas
    map.once("rendercomplete", function () {
      map
        .getViewport()
        .querySelector("canvas")
        .toBlob(function (blob) {
          // Create download link
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";

          // Create object URL
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "map_snapshot.png";
          a.click();

          // Cleanup
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        });
    });

    map.renderSync();
  };

  const handleShare = () => {
    // Implement the logic for handling share icon
  };

  const toggleDrawing = () => {
    setDrawIsActive(!drawIsActive);
  };



  return (
    <>

      <MyMapSmall zoom={zoom} center={center} getMapRef={setMapRef}>
        <Snackbar
          className={classes.snackbar}
          open={open}
          onClose={handleClose}
          style={{ left: '72%', 'bottom': '30%', backgroundColor: '#fff', color: '#0000000', width: 'auto' }}
        >
          <SnackbarContent
            className={classes.snackbarContent}
            message={popUpData}
          />

        </Snackbar>
        <MyLayers>
          <DrawOnMap type="Polygon" isActive={drawIsActive} />
          {featureData !== "" && featureData &&
            featureData.features.length > 0
            ? (
              <VectorLayer
                zoomTo
                data={featureData}
                source={vector({
                  features: new GeoJSON()
                    .readFeatures(featureData)
                    .filter((x) => x.getGeometry() != null),
                })}
                style={(feature) => {
                  if (selectedConstituency == "")
                    return getSpiColor(feature, selectedState);
                  else
                    return getSpiColor(feature, selectedConstituency);
                }}
                name="AdministrativeBoundary"
                zIndex={10}
              />
            ) : ('')}
          <VectorLayer
            source={vector({
              features: new GeoJSON().readFeatures({
                type: "FeatureCollection",
                features: [],
              }),
            })}
            style={
              new Style({
                stroke: new Stroke({
                  color: "yellow",
                  width: 3,
                }),
                fill: new Fill({
                  color: "rgba(0, 0, 255, 0.1)",
                }),
              })
            }
            name="region"
          />

          {fishnetLayer !== null && (
            <TileLayer
              name="fishnetWms"
              source={wms({
                url: "http://65.21.126.12:8090/geoserver/agtull/wms",
                layer: `agtull:${fishnetLayer.layerName}`,
                filter: `BBOX(geom, ${fishnetLayer.wktGeom})`,
              })}
              zIndex={2}
            />
          )}
        </MyLayers>
      </MyMapSmall>
    </>
  );
}

export default MapViewAnalyticsSmall;
