import React, { useState, useEffect } from "react";
import "../../mainScreen.css";
import { FaUserAlt } from "react-icons/fa";
import { IoMdHome, IoMdMenu } from "react-icons/io";
import { IoIosSettings } from "react-icons/io";
import { Auth } from "aws-amplify";
import { MdEdit, MdDelete } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { FaCircleUser, FaChevronUp } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination, // Import TablePagination
} from "@mui/material";
import AddUser from "./AddUser";
import { IoIosArrowForward, IoIosLogOut } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const AdminMain = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [addUserScreen, setaddUserScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]); // State to store users from API
  const [page, setPage] = useState(0); // State for pagination
  const [rowsPerPage, setRowsPerPage] = useState(5); // State for rows per page
  const LoginEmail = useSelector((state) => state.LoginEmail);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const response = await fetch("/api/admin/getAllUser", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          console.error("Failed to fetch users");
          toast.error("Failed to fetch users");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("An error occurred while fetching users");
      }
    };

    fetchUsers();
  }, []);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleMenuToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };

  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDeleteUser = async (email) => {
    const { accessToken: { jwtToken } } = await Auth.currentSession();
    try {
      const response = await fetch("/api/admin/deleteAccount", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast.success("User deleted successfully");
        setUsers(users.filter((user) => user.email !== email));
      } else {
        console.log("Failed to delete user");
        toast.error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An error occurred while deleting the user");
    }
  };

  return (
    <>
      {addUserScreen === true ? (
        <AddUser
          setadminSettingsScreen={props.setadminSettingsScreen}
          setcalulateriskmainscreen={props.setcalulateriskmainscreen}
          mainscreen={props.mainscreen}
          setmonitorscreen={props.setmonitorscreen}
          setMainscreen={props.setMainscreen}
          setriskscreen={props.setriskscreen}
          setoutputportfolioscreen={props.setoutputportfolioscreen}
          advisorycreen={props.advisorycreen}
          setadvisorycreen={props.setadvisorycreen}
          setadminProfilescreen={props.setadminProfilescreen}
          setaddUserScreen={setaddUserScreen}
        />
      ) : (
        <div className="main-screen" style={{ display: "flex" }}>
           <div
            style={{
              background: "#00253D",
              height: "100vh",
              zIndex: "1001",
              position: "relative",
              width: isCollapsed ? "80px" : "auto", // Adjust width for collapsed state
              transition: "width 0.3s",
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <IoMdMenu color="white" size={28} onClick={handleMenuToggle} />
                {!isCollapsed && (
                  <span
                    style={{
                      marginLeft: "10px",
                      color: "white",
                      fontSize: "22px",
                    }}
                  >
                    TARA
                  </span>
                )}
              </div>

              {/* Sidebar Links */}

              <div
                className="custom-buttonnav"
                style={{
                  padding: "20px",
                  display: "flex",
                  cursor: "pointer",
                  background: "#F5821F",
                }}
              >
                <FaUserAlt
                  color="white"
                  size={28}
                  style={{ marginRight: isCollapsed ? "0" : "10px" }}
                />
                {!isCollapsed && (
                  <span style={{ color: "white", fontSize: "18px" }}>
                    User Management
                  </span>
                )}
              </div>

              <div
                className="custom-buttonnav"
                style={{ padding: "20px", display: "flex", cursor: "pointer" }}
                onClick={() => {
                  props.setadminSettingsScreen(true);
                  props.setadminProfilescreen(false);
                  props.setadvisorycreen(false);
                  props.setmonitorscreen(false);
                  props.setMainscreen(false);
                  props.setcalulateriskmainscreen(false);
                  props.setoutputportfolioscreen(false);
                }}
              >
                <IoIosSettings
                  color="white"
                  size={28}
                  style={{ marginRight: isCollapsed ? "0" : "10px" }}
                />
                {!isCollapsed && (
                  <span style={{ color: "white", fontSize: "18px" }}>
                    User Settings
                  </span>
                )}
              </div>
            </div>

            <div style={{ position: "relative", width: "100%" }}>
              <div
                className="custom-buttonnav"
                style={{
                  padding: "15px",
                  display: "flex",
                  width: "auto",
                  cursor: "pointer",
                  alignItems: "center",
                  borderTop: "2px solid white",
                  position: "relative",
                }}
                onClick={togglePopup}
              >
                {showPopup && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-110px",
                      left: "10px",
                      backgroundColor: "white",
                      color: "black",
                      padding: "10px",
                      borderRadius: "5px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      zIndex: "1000",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        props.setadminProfilescreen(false);
                        props.setadvisorycreen(false);
                        props.setmonitorscreen(false);
                        props.setMainscreen(false);
                        props.setcalulateriskmainscreen(false);
                        props.setoutputportfolioscreen(true);
                      }}
                    >
                      <IoSettingsOutline style={{ marginRight: "8px" }} />{" "}
                      Switch to user
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        props.setPasswordSettingscreen(true);
                        props.setadminProfilescreen(false);
                        props.setmonitorscreen(false);
                        props.setMainscreen(false);
                        props.setcalulateriskmainscreen(false);
                        props.setoutputportfolioscreen(false);
                      }}
                    >
                      <IoSettingsOutline style={{ marginRight: "8px" }} />{" "}
                      Settings
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0",
                        cursor: "pointer",
                      }}
                      onClick={handleLogout}
                    >
                      <IoLogOutOutline style={{ marginRight: "8px" }} /> Logout
                    </div>
                  </div>
                )}

                <FaCircleUser
                  color="white"
                  size={25}
                  style={{ marginRight: isCollapsed ? "0" : "8px" }}
                />
                {!isCollapsed && (
                  <>
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      {LoginEmail}
                    </span>
                    <IoIosArrowForward color="white" size={20} />
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Main Content Section */}
          <div style={{ flex: 1, padding: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1 style={{ fontSize: "28px", fontWeight: "bold" }}>
                User Management
              </h1>
            </div>

            {/* Search and Add User Button */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  padding: "10px",
                  width: "620px",
                  boxSizing: "border-box",
                }}
              />
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#00253D",
                  color: "white",
                  padding: "10px 20px",
                  width: "172px",
                }}
                onClick={() => setaddUserScreen(true)}
              >
                Add User
              </Button>
            </div>

            {/* User Table */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "lightgrey" }}>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>User Role</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.user_role}</TableCell>
                        <TableCell>{user.group_name}</TableCell>
                        <TableCell>
                          <Button onClick={() => handleDeleteUser(user.email)}>
                            <MdDelete />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              {/* Table Pagination */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
            <ToastContainer />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminMain;
