import React, { useState, useEffect } from 'react';
import { Auth } from "aws-amplify";
import '../HomePage.css';
import { Amplify } from "aws-amplify";
import awsmobile from "../aws-exports";
//import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useSelector} from 'react-redux';


Amplify.configure(awsmobile);

const MainLayout = () => {
    const [startMonth, setStartMonth] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [county, setCounty] = useState('');
    const [constituency, setConstituency] = useState('');
    const [ward, setWard] = useState('');
    const [result, setResult] = useState('');
    const [startmonths, setStartMonths] = useState([]);
    const [endmonths, setEndMonths] = useState('');
    const [counties, setCounties] = useState([]);
    const [constituencies, setConstituencies] = useState([]);
    const [wards, setWards] = useState([]);
    const [selectedCrop, setSelectedCrop] = useState('');
    const [cropProbabilityData, setCropProbabilityData] = useState({});
    const [availableCrops, setAvailableCrops] = useState([]);
    const [cropRankingData, setCropRankingData] = useState([]);
    const [accordian1Open, setAccordian1Open] = useState(false);
    const [accordian2Open, setAccordian2Open] = useState(false);
    const [accordian3Open, setAccordian3Open] = useState(false);
    const [fullSeasonValue, setFullSeasonValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const cropParameterFile = useSelector((state) => state.cropParameterFile);


    useEffect(() => {
        async function fetchData() {
            try {
                const { accessToken: { jwtToken } } = await Auth.currentSession();
                // Fetch start month
                const startMonthResponse = await fetch(`/api/homepage/selectBlockStartMonth`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: jwtToken,
                    },
                });
                if (startMonthResponse.ok) {
                    const startMonthData = await startMonthResponse.json();
                    setStartMonths(startMonthData.StartMonths);
                } else {
                    console.error('Failed to fetch start month:', startMonthResponse.statusText);
                }
                
                // Fetch end month
                const endMonthResponse = await fetch(`/api/homepage/selectBlockEndMonth`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: jwtToken,
                    },
                });
                if (endMonthResponse.ok) {
                    const endMonthData = await endMonthResponse.json();
                    setEndMonths(endMonthData.EndMonth);
                } else {
                    console.error('Failed to fetch end month:', endMonthResponse.statusText);
                }

                // Fetch counties
                const countyResponse = await fetch(`/api/homepage/selectCounty`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        //Authorization: jwtToken,
                    },
                });
                if (countyResponse.ok) {
                    const countyData = await countyResponse.json();
                    setCounties(countyData.county);
                } else {
                    console.error('Failed to fetch counties:', countyResponse.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    const handleCountyChange = (selectedCounty) => {
        setCounty(selectedCounty);
        setConstituency('');
        setWard('');
        Auth.currentSession()
            .then((session) => {
                const jwtToken = session.accessToken.jwtToken;
                fetch(`/api/homepage/selectConstituency?county=${selectedCounty}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: jwtToken,
                    },
                })
                .then(response => response.json())
                .then(data => setConstituencies(data.constituency))
                .catch(error => console.error('Error fetching constituencies:', error));
            })
            .catch(error => console.error('Error getting session:', error));
    };

    const handleConstituencyChange = (selectedConstituency) => {
        setConstituency(selectedConstituency);
        setWard('');
        Auth.currentSession()
            .then((session) => {
                const jwtToken = session.accessToken.jwtToken;
                fetch(`/api/homepage/selectWard?county=${county}&constituency=${selectedConstituency}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: jwtToken,
                    },
                })
                .then(response => response.json())
                .then(data => setWards(data.ward))
                .catch(error => console.error('Error fetching wards:', error));
            })
            .catch(error => console.error('Error getting session:', error));
    };
    
    const handleGenerateResult = async () => {
        setIsLoading(true);
        try {
            const { accessToken: { jwtToken } } = await Auth.currentSession();
            
            // Fetch crop parameters
            const cropParametersResponse = await fetch(`/api/homepage/calculateCropParameters?ward_name=${ward}&start_month=${startMonth}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: jwtToken,
                },
            });
    
            // Fetch crop ranking data
            const cropRankingResponse = await fetch(`/api/homepage/cropRanking?ward_name=${ward}&start_month=${startMonth}`, {
                method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
            'Content-Type': 'multipart/form-data',
          },
          body: cropParameterFile
        });
    
            // Wait for both responses
            const [cropParametersData, cropRankingData] = await Promise.all([cropParametersResponse, cropRankingResponse])
                .then(responses => Promise.all(responses.map(response => response.json())));
    
            // Update the state with the received data
            setResult(JSON.stringify(cropParametersData));
            
            // Convert crop ranking object to array of objects
            const cropRankingArray = Object.entries(cropRankingData).map(([cropName, score]) => ({
                cropName: cropName,
                score: score
            }));
    
            // Update the state with the converted array
            setCropRankingData(cropRankingArray);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false); // Set loading to false after API calls are completed
        }
    };
    
    const toggleAccordian1 = () => {
        setAccordian1Open(!accordian1Open);
        if (accordian2Open) {
            setAccordian2Open(false);
        }
        if (accordian3Open) {
            setAccordian3Open(false);
        }
    };

    const toggleAccordian2 = () => {
        setAccordian2Open(!accordian2Open);
        if (accordian1Open) {
            setAccordian1Open(false);
        }
        if (accordian3Open) {
            setAccordian3Open(false);
        }
    };

    const toggleAccordian3 = () => {
        setAccordian3Open(!accordian3Open);
        if (accordian1Open) {
            setAccordian1Open(false);
        }
        if (accordian2Open) {
            setAccordian2Open(false);
        }
    };

    
    useEffect(() => {
        async function fetchCrops() {
            try {
                const { accessToken: { jwtToken } } = await Auth.currentSession();
                const response = await fetch(`/api/homepage/crops`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: jwtToken,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setAvailableCrops(data.crops);
                } else {
                    console.error('Failed to fetch available crops:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching available crops:', error);
            }
        }
        fetchCrops();
    }, []);

    const handleCropChange = (selectedCrop) => {
        setSelectedCrop(selectedCrop);
        Auth.currentSession()
            .then((session) => {
                const jwtToken = session.accessToken.jwtToken;
                // Fetch crop percentage probability data based on selected crop and ward
                fetch(`/api/homepage/cropPercentageProbability?ward_name=${ward}&crop_name=${selectedCrop}&start_month=${startMonth}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: jwtToken,
                    },
                })
                .then(response => response.json())
                .then(data => {
                    //console.log("Crop Probability Data:", data); // Log the data here
                    // Transform data for Highcharts
                    const transformedData = [
                        { name: 'Germination', y: parseFloat(data[selectedCrop]['germination'].replace('%', '')) },
                        { name: 'Vegetative', y: parseFloat(data[selectedCrop]['vegetative'].replace('%', '')) },
                        { name: 'Flowering', y: parseFloat(data[selectedCrop]['flowering'].replace('%', '')) },
                        { name: 'Pre-harvest', y: parseFloat(data[selectedCrop]['pre-harvest'].replace('%', '')) }
                    ];
                
                    // Map through transformedData and assign colors based on values
                    const coloredBars = transformedData.map(bar => ({
                        name: bar.name,
                        y: bar.y,
                        color: determineColor(bar.y) // Use determineColor function to get the color
                    }));
                
                    setCropProbabilityData(coloredBars); // Update state with colored bars
                    setFullSeasonValue(data[selectedCrop]['Full Season']);
                })
                .catch(error => console.error('Error fetching crop percentage probability:', error));
            }) 
            .catch(error => console.error('Error getting session:', error));
    };
    
    
    
    const determineColor = (value) => {
        if (value >= 70 && value <= 100) {
            return '#016b01';
        } else if (value >= 40 && value < 70) {
            return '#feb700';
        } else if (value >= 0 && value < 40) {
            return '#fd402c';
        }
    };

    const fullSeasonPercentage = parseFloat(fullSeasonValue.replace('%', ''));
    let circleBorderColor = '';

    if (fullSeasonPercentage >= 70 && fullSeasonPercentage <= 100) {
    circleBorderColor = '#016b01';
    } else if (fullSeasonPercentage >= 40 && fullSeasonPercentage < 70) {
    circleBorderColor = '#feb700';
    } else if (fullSeasonPercentage >= 0 && fullSeasonPercentage < 40) {
    circleBorderColor = '#fd402c';
    }

    const sortedCropRankingData = cropRankingData.sort((a, b) => b.score - b.score);

    return (
        <div className="container">
            <div className="left-panel">
            <h2 style={{fontWeight:'bolder',fontSize:'x-large'}}>Tool for Agricultural Risk Assessment and Advisory</h2>
            <div class="heading-container" onClick={toggleAccordian1}>
                    <h2>CROP SUCCESS PROBABILITY CALCULATOR         
                    {accordian1Open ? <FaChevronUp className="arrow" /> : <FaChevronDown  className="arrow"  />}</h2>
                </div>
                {accordian1Open && (
                    <div classname="accordian1">
                    <div className="dropdown">
                        <label htmlFor="startMonth">Planting Month:</label>
                        <select id="startMonth" value={startMonth} onChange={(e) => setStartMonth(e.target.value)}>
                            <option value="">Select Start Month</option>
                             <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </select>
                    </div>
                    <div className="dropdown">
                        <label htmlFor="county">Select County:</label>
                        <select id="county" value={county} onChange={(e) => handleCountyChange(e.target.value)}>
                            <option value="">Select County</option>
                            {counties.map((county, index) => (
                                <option key={index} value={county}>{county}</option>
                            ))}
                        </select>
                    </div>
                    <div className="dropdown">
                        <label htmlFor="constituency">Select Constituency:</label>
                        <select id="constituency" value={constituency} onChange={(e) => handleConstituencyChange(e.target.value)}>
                            <option value="">Select Constituency</option>
                            {constituencies.map((constituency, index) => (
                                <option key={index} value={constituency}>{constituency}</option>
                            ))}
                        </select>
                    </div>
                    <div className="dropdown">
                        <label htmlFor="ward">Select Ward:</label>
                        <select id="ward" value={ward} onChange={(e) => setWard(e.target.value)}>
                            <option value="">Select Ward</option>
                            {wards.map((ward, index) => (
                                <option key={index} value={ward}>{ward}</option>
                            ))}
                        </select>
                    </div>
                    <button className='button' onClick={handleGenerateResult}>Generate Result</button>
                    </div>
                )}
                <div class="heading-container" onClick={toggleAccordian2}>
                    <h2>CROP CALENDER & ADVISORY 
                        {accordian2Open ?  <FaChevronUp className="arrow" /> : <FaChevronDown  className="arrow"  />}
                         
                    </h2>
                </div>
                {accordian2Open && (
                    <div classname="accordian2">
                    <div className="dropdown">
                        <label htmlFor="startMonth">Start Month:</label>
                        <select id="startMonth" value={startMonth} onChange={(e) => setStartMonth(e.target.value)}>
                            <option value="">Select Start Month</option>
                            <option value={startmonths}>{startmonths}</option>
                        </select>
                    </div>
                    <div className="dropdown">
                        <label htmlFor="endMonth">End Month:</label>
                        <select id="endMonth" value={endMonth} onChange={(e) => setEndMonth(e.target.value)}>
                            <option value="">Select End Month</option>
                            <option value={endmonths}>{endmonths}</option>
                        </select>
                    </div>
                    <div className="dropdown">
                        <label htmlFor="county">Select County:</label>
                        <select id="county" value={county} onChange={(e) => handleCountyChange(e.target.value)}>
                            <option value="">Select County</option>
                            {counties.map((county, index) => (
                                <option key={index} value={county}>{county}</option>
                            ))}
                        </select>
                    </div>
                    <div className="dropdown">
                        <label htmlFor="constituency">Select Constituency:</label>
                        <select id="constituency" value={constituency} onChange={(e) => handleConstituencyChange(e.target.value)}>
                            <option value="">Select Constituency</option>
                            {constituencies.map((constituency, index) => (
                                <option key={index} value={constituency}>{constituency}</option>
                            ))}
                        </select>
                    </div>
                    <div className="dropdown">
                        <label htmlFor="ward">Select Ward:</label>
                        <select id="ward" value={ward} onChange={(e) => setWard(e.target.value)}>
                            <option value="">Select Ward</option>
                            {wards.map((ward, index) => (
                                <option key={index} value={ward}>{ward}</option>
                            ))}
                        </select>
                    </div>
                    <button className='button'>COMING SOON</button>
                    </div>
                )}
                <div class="heading-container" onClick={toggleAccordian3}>
                    <h2>CROP HEALTH MONITORING
                    {accordian2Open ?  <FaChevronUp className="arrow" /> : <FaChevronDown  className="arrow"  />}
                    </h2>
                </div>
                {accordian3Open && (
                      <div classname="accordian3">
                
                      <div className="dropdown">
                          <label htmlFor="county">Select Value 1:</label>
                          <select id="county" value={county} onChange={(e) => handleCountyChange(e.target.value)}>
                              <option value="">Select Value 1</option>
                              {counties.map((county, index) => (
                                  <option key={index} value={county}>{county}</option>
                              ))}
                          </select> 
                      </div>
                     
                      <div className="dropdown">
                          <label htmlFor="ward">Select Value 2:</label>
                          <select id="ward" value={ward} onChange={(e) => setWard(e.target.value)}>
                              <option value="">Select Value 2</option>
                              {wards.map((ward, index) => (
                                  <option key={index} value={ward}>{ward}</option>
                              ))}
                          </select>
                      </div>
                      <button className='button'>COMING SOON</button>
                      </div>
                )}
                <div className="icon-container">
                    <img src="acre.png" alt="Icon" className="icon" />
                </div>
            </div>
            {isLoading ? (
                <div class="loading-screen" id="loading-screen">
                    <div class="loading-spinner-container">
                        <div class="loading-spinner"></div>
                    </div>
                </div>
            ) : (
            <div className="right-panel">
                <div className="graph-container">
                    <div className="upper-half">
                        <h2 style={{textAlign: 'center'}}>Crop Ranking and Suitability</h2>
                        <table className="crop-table">
                            <thead>
                                <tr>
                                    <th className="header-cell">Crop</th>
                                    <th className="header-cell">Full Season Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedCropRankingData.map((crop, index) => (
                                    <tr key={index} style={{height:'5px'}}>
                                        <td>{crop.cropName}</td>
                                        <td>{crop.score}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="dropdown" style={{ textAlign: 'center' }}>
                        <h4>Select Crop</h4>
                        <select id="crop" value={selectedCrop} onChange={(e) => handleCropChange(e.target.value)} style={{ width: '25%' }}>
                            <option value="">Select Crop</option>
                            {/* Populate options with available crops */}
                            {availableCrops.map((crop, index) => (
                                <option key={index} value={crop}>{crop}</option>
                            ))}
                        </select>
                    </div>
                    <div className="lower-half">
                        <div className="charts-container"></div>
                            <div className="annular-circle">
                                <h3>Climate Risk Score</h3>
                                <div className="circle" style={{ borderColor: circleBorderColor }}>
                                    <div className="inner-circle">
                                        <p>{fullSeasonValue}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="histogram">
                                    <h3>Crop Percentage Probability Histogram</h3>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                            chart: {
                                                type: 'column'
                                            },
                                            title: {
                                                text: 'Percentage Probability vs Growth Stage'
                                            },
                                            xAxis: {
                                                type: 'category',
                                                title: {
                                                    text: 'Growth Stage'
                                                }
                                            },
                                            yAxis: {
                                                title: {
                                                    text: 'Percentage Probability'
                                                }
                                            },
                                            series: [{
                                                name: 'Percentage Probability',
                                                data: cropProbabilityData,
                                                // Specify colors for each data point in the series
                                                colorByPoint: true,
                                                colors: ['#038b00', '#06b803', '#ffeb03', '#feb700']
                                            }]
                                        }}
                                    />
                            </div>
                        </div>
                        <div className="legend-container">
                        <h3>Legend</h3>
                            <div className="legend-box">
                                <div className="legends" style={{display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '5px',
                                    fontFamily: 'Arial, sans-serif',
                                    fontSize: '14px'}}>
                                    <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                                    <div
                                        className="legend-circle"
                                        style={{ backgroundColor: '#016b01', width: '20px', height: '12px', borderRadius: '100%' }}
                                    ></div>
                                    <span style={{ marginLeft: '5px' }}>
                                        Lending is advisable since crop is well suited to the area and its climatic conditions
                                    </span>
                                    </div>
                                    <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                                        <div
                                            className="legend-circle"
                                            style={{ backgroundColor: '#feb700', width: '20px', height: '12px', borderRadius: '50%' }}
                                        ></div>
                                        <span style={{ marginLeft: '5px' }}>
                                            Lending is advisable along with insurance coverage as crop faces certain weather risks in the
                                            area
                                        </span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div
                                                className="legend-circle"
                                                style={{ backgroundColor: '#fd402c', width: '20px', height: '12px', borderRadius: '50%' }}
                                            ></div>
                                            <span style={{ marginLeft: '5px' }}>
                                                Lending is not advisable as crop is not suited to the area
                                            </span>
                                        </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>)}
            <div
      className="row "
      style={{
        position: "fixed",
        bottom: "0", 
        right:"0",
        backgroundColor: "#91b187",
        zIndex: "1000", 
        width:'80%',
        justifyContent:'center',
        display:'flex'
      }}
    >
             <div
              style={{
                textAlign:'center',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                marginTop:10,
              }}>
           
            </div>
            Powered by 
            <img
              style={{
                width: "25px",
                marginLeft: "5px",
              }}
              src="white.png"
            />
          </div> 
        </div>
    );  
};

export default MainLayout;
