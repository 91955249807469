import React, { useState, useEffect, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  TextField,
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewMoreScreen from "./ViewMoreScreen"; // Import the ViewMoreScreen component
import { useDispatch, useSelector } from "react-redux";
import { changeLoanID } from "../redux/action";
import { Auth } from "aws-amplify";

const FarmerTable = (props) => {
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCrops, setSelectedCrops] = useState([]);
  const [viewMoreData, setViewMoreData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("county");
  const [selectedSeasons, setSelectedSeasons] = useState([]);
  const [farmers, setFarmers] = useState([]); // State to hold the farmer data
  const LoanID = useSelector((state) => state.LoanID);
  const dispatch = useDispatch();

  // State to store the counties and crops fetched from the API
  const [counties, setCounties] = useState([]);
  const [crops, setCrops] = useState([]);

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        // Retrieve the JWT token
        const { accessToken: { jwtToken } } = await Auth.currentSession();
  
        // Make the API request with the token in the Authorization header
        const response = await fetch(
          "/api/homepage/selectLocation?country=Kenya&level=county",
          {
            method: "GET", // Define the HTTP method (optional, GET is default)
            headers: {
              "Content-Type": "application/json",
              Authorization:jwtToken, // Set the Authorization header
            },
          }
        );
  
        // Check if the response is okay
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        const data = await response.json();
        setCounties(data); // Assuming the API returns a 'counties' array
      } catch (error) {
        console.error("Error fetching counties:", error);
      }
    };
  
    fetchCounties();
  }, []);
  
  // Fetch crops from the API when the component mounts
  useEffect(() => {
    const fetchCrops = async () => {
      try {
        const response = await fetch("/api/homepage/crops"); // Assuming this is the correct API endpoint
        const data = await response.json();
        setCrops(data.crops); // Assuming the API returns a 'crops' array
      } catch (error) {
        console.error("Error fetching crops:", error);
      }
    };

    fetchCrops();
  }, []);
  const fetchFarmers = async () => {
    try {
      const response = await fetch("/api/monitoring/customerViewTable"); // Fetching farmer data
      const data = await response.json();

      // Map the response data to match the required structure
      const formattedFarmers = data.map((farmer) => ({
        name: farmer.first_name,
        loanId: farmer.unique_id_number, // assuming unique_id_number is your Loan ID
        loanAmount: `$${farmer.total_loan_amount}`, // Formatting loan amount
        crop: farmer.crop_name,
        season: farmer.season,
        loanDuration: `${farmer.loan_term_in_months} months`,
        loanType: "Short-term", // This needs clarification; adjust accordingly
        county: "Not Specified", // Add logic to derive county if applicable
      }));

      setFarmers(formattedFarmers); // Update the farmers state with the formatted data
    } catch (error) {
      console.error("Error fetching farmers:", error);
    }
  };

  // Fetch farmers when the component mounts
  useEffect(() => {
    fetchFarmers();
  }, []);
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleCloseWithReset = () => {
    setSelectedCountries([]);
    setSelectedCrops([]);
    handleFilterClose();
  };

  const handleCountryChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCountries((prev) =>
      checked ? [...prev, value] : prev.filter((county) => county !== value)
    );
  };

  const handleCropChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCrops((prev) =>
      checked ? [...prev, value] : prev.filter((crop) => crop !== value)
    );
  };
  const handleSeasonChange = (event) => {
    const season = event.target.value;
    setSelectedSeasons((prevSelectedSeasons) =>
      prevSelectedSeasons.includes(season)
        ? prevSelectedSeasons.filter((s) => s !== season)
        : [...prevSelectedSeasons, season]
    );
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleViewMore = (farmer) => {
    setViewMoreData(farmer);
    dispatch(changeLoanID(farmer.loanId));
  };

  const handleBackClick = () => {
    setViewMoreData(null);
    fetchFarmers()
  };
  const filteredFarmers = farmers.filter((farmer) => {
    // Check both name and loanId for case-insensitive match with the search input
    const nameMatch = farmer.name
      ? farmer.name.toLowerCase().includes(search.toLowerCase())
      : false;
    const loanIdMatch = farmer.loanId
      ? String(farmer.loanId).toLowerCase().includes(search.toLowerCase())
      : false;
  
    return (
      (nameMatch || loanIdMatch) &&
      (selectedCountries.length === 0 || selectedCountries.includes(farmer.county)) &&
      (selectedCrops.length === 0 || selectedCrops.includes(farmer.crop)) &&
      (selectedSeasons.length === 0 || selectedSeasons.includes(farmer.season))
    );
  });
  

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Farmer Name",
      },
      {
        accessorKey: "loanId",
        header: "Loan ID",
      },
      {
        accessorKey: "loanAmount",
        header: "Loan Amount",
      },
      {
        accessorKey: "crop",
        header: "Crop",
      },
      {
        accessorKey: "season",
        header: "Season",
      },
      {
        accessorKey: "loanDuration",
        header: "Loan Duration",
      },
      {
        id: "actions",
        header: "Actions",
        Cell: ({ row }) => (
          <span
            onClick={() => handleViewMore(row.original)}
            style={{
              color: "#F2A70D",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            View More
          </span>
        ),
      },
    ],
    []
  );

  const selectedItemStyle = { backgroundColor: "#718E68", color: "white" };

  return (
    <div
      style={{
        height: "100vh",
        overflow: "scroll",
        padding: props.isCollapsed ? "15px" : "25px",
        width: props.isCollapsed ? "95vw" : "90vw",
      }}
    >
      {viewMoreData ? (
        <ViewMoreScreen
          isCollapsed={props.isCollapsed}
          setIsCollapsed={props.setIsCollapsed}
          farmer={viewMoreData}
          onBackClick={handleBackClick}
        />
      ) : (
        <>
          <div style={{ fontSize: "28px", fontWeight: 700 }}>Customer View</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontFamily: "Dosis",
              marginBottom: "30px",
            }}
          >
            <TextField
              label="Search by Farmer Name or Loan ID"
              variant="outlined"
              margin="normal"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ width: "480px", height: "40px" }}
              InputProps={{
                style: { height: "40px" },
              }}
            />
            <Button
              variant="contained"
              onClick={handleFilterClick}
              startIcon={<FilterListIcon />}
              style={{
                height: "40px",
                background: "none",
                color: "#718E68",
                fontWeight: 700,
              }}
            >
              Filter
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleFilterClose}
              PaperProps={{
                style: {
                  display: "flex",
                  flexDirection: "row",
                  minWidth: "600px",
                  maxWidth: "600px",
                },
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "180px",
                    borderRight: "1px solid grey",
                  }}
                >
                  <MenuItem
                    style={
                      selectedCategory === "county" ? selectedItemStyle : {}
                    }
                    onClick={() => handleCategoryClick("county")}
                  >
                    County
                  </MenuItem>
                  <MenuItem
                    style={
                      selectedCategory === "Season" ? selectedItemStyle : {}
                    }
                    onClick={() => handleCategoryClick("Season")}
                  >
                    Season
                  </MenuItem>
                  <MenuItem
                    style={selectedCategory === "crop" ? selectedItemStyle : {}}
                    onClick={() => handleCategoryClick("crop")}
                  >
                    Crop
                  </MenuItem>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    position: "relative",
                    width: "400px",
                  }}
                >
                  {selectedCategory === "county" && (
                    <div>
                      <h3>County</h3>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {counties &&
                          counties.map((county) => (
                            <FormControlLabel
                              key={county}
                              control={
                                <Checkbox
                                  value={county}
                                  checked={selectedCountries.includes(county)}
                                  onChange={handleCountryChange}
                                />
                              }
                              label={county}
                            />
                          ))}
                      </div>
                    </div>
                  )}

                  {selectedCategory === "Season" && (
                    <div>
                      <h3>Season</h3>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "10px",
                          marginLeft: "10px",
                          marginBottom: "40px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="Long Rain"
                              checked={selectedSeasons.includes("Long Rain")}
                              onChange={handleSeasonChange}
                            />
                          }
                          label="Long Rain"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="Short Rain"
                              checked={selectedSeasons.includes("Short Rain")}
                              onChange={handleSeasonChange}
                            />
                          }
                          label="Short Rain"
                        />
                      </div>
                    </div>
                  )}
                  {selectedCategory === "crop" && (
                    <div>
                      <h3>Crop</h3>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "10px",
                          marginLeft: "10px",
                          marginBottom: "40px",
                        }}
                      >
                        {crops &&
                          crops.map((crop) => (
                            <FormControlLabel
                              key={crop}
                              control={
                                <Checkbox
                                  value={crop}
                                  checked={selectedCrops.includes(crop)}
                                  onChange={handleCropChange}
                                />
                              }
                              label={crop}
                            />
                          ))}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      bottom: "16px",
                      right: "1px",
                      display: "flex",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      onClick={handleFilterClose}
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "8px" }}
                    >
                      Apply
                    </Button>
                    <Button onClick={handleCloseWithReset} color="primary">
                      clear
                    </Button>
                  </div>
                </div>
              </div>
            </Menu>
          </div>
          <div style={{ height: "100%", maxWidth: "100%" }}>
            <MaterialReactTable
              columns={columns}
              data={filteredFarmers}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableSorting={false}
              enablePagination={true} // Enable pagination
              muiTablePaginationProps={{
                rowsPerPageOptions: [15], // Set 15 rows per page
                SelectProps: { native: true }, // Optional: Use native select for pagination
              }}
              initialState={{
                pagination: { pageSize: 15 }, // Show max 15 rows per page
              }}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => handleViewMore(row.original),
                style: {
                  cursor: "pointer",
                },
              })}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FarmerTable;
